<template>
    <BT-Blade-Items
        addBladeName="courier-invoice"
        bladeName="courier-invoices"
        :bladesData="bladesData"
        :canCreate="false"
        :headers="[
            { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: 'INV#' }, 
            { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' }, 
            { text: 'Courier', value: 'seller.companyName', title: 1 },
            { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
            { text: 'Status', value: 'isPaid', display: true }]"
        hideActions
        navigation="courier-invoices"
        :showDelete="false"
        title="Courier Invoices"
        useServerPagination>
            <template v-slot:isPaid="{ item }">
                {{ item.isPaid ? 'Paid' : 'Unpaid' }}
            </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Courier-Invoices',
    props: {
        bladesData: null
    }
}
</script>
