var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"courier-invoice","bladeName":"courier-invoices","bladesData":_vm.bladesData,"canCreate":false,"headers":[
        { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: 'INV#' }, 
        { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1, prefix: 'Due: ' }, 
        { text: 'Courier', value: 'seller.companyName', title: 1 },
        { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
        { text: 'Status', value: 'isPaid', display: true }],"hideActions":"","navigation":"courier-invoices","showDelete":false,"title":"Courier Invoices","useServerPagination":""},scopedSlots:_vm._u([{key:"isPaid",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaid ? 'Paid' : 'Unpaid')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }